// Libraries
export default class Format {
  // Calculate celsius to fahrenheit
  static celsiusToFahrenheit (celsius = null) {
    return celsius && ((celsius * 9) / 5 + 32);
  }

  // Add wildcard before each word
  static addWildcardPerWord (value) {
    if (typeof value === 'string' && value.length > 0) {
      // Add wildcard before each word
      // First, split the words
      let wildcardedValue = value.split(' ');

      // Map the words and add a *, if necessary
      wildcardedValue = wildcardedValue.map((word) => {
        if (word.substr(0, 1) !== '*') {
          return `*${word}*`;
        }
        return word;
      });

      // Join the wordt again
      return wildcardedValue.join(' ');
    }
    return value;
  }

  // Capitalize first letter
  static capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static bitsToArray (bits) {
    return (bits).toString(2).split('').reverse().map((bit) => Number(bit));
  }

  static sunbedHours (totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} Hours ${minutes < 10 ? `0${minutes}` : minutes} minutes`;
  }

  static secondsToMinutesSeconds (totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.round(totalSeconds % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }

  static humanizeSessionTime (totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.round(totalSeconds % 60);
    return `${minutes} minutes, ${seconds < 10 ? `0${seconds}` : seconds} seconds`;
  }
}
