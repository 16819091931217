<template>
  <div class="container">
    <form class="form" @submit.prevent="onSubmit">
      <div class="form-group row border-bottom pb-3 mb-3">
        <div class="col-12 col-md-9 col-form-label my-md-auto">
          <h5 class="overflow-ellipsis">Export period</h5>
          <span>Select the desired period.</span>
        </div>
        <div class="col col-form-label mt-3 my-md-auto">
           <b-dropdown
            ref="range-dropdown"
            variant="outline-primary"
            class="btn-block btn-clean btn-dropdown dropdown-menu-top-unround"
            right
            lazy
            no-caret
            menu-class="w-100 w-md-500px"
            :disabled="isLoadingForm"
          >
            <template v-slot:button-content>
              <i class="mt-n1">
                <font-awesome-icon :icon="['fas', 'calendar-alt']" />
              </i>

              <span class="pl-3">
                <template v-if="$moment(form.range.start).isSame(form.range.end, 'date')">{{ $moment(form.range.start).format("LL") }}</template>
                <template v-else>{{ $moment(form.range.start).format("LL") }} - {{ $moment(form.range.end).format("LL") }}</template>
              </span>
            </template>

            <template v-slot:default>
            <div class="row mx-0">
              <div class="col-12 border-bottom col-md-8 border-bottom-md-0 border-right-md">
                <date-picker
                  mode="date"
                  class="border-0 my-auto"
                  color="primary"
                  locale="en"
                  is-range
                  is-expanded

                  :value="form.range"
                  :min-date="datePicker.minDate"
                  :max-date="datePicker.maxDate"
                  :input-props="{ class: 'border-0' }"
                  :first-day-of-week="2"

                  @input="onDatePickerInput"
                />
              </div>

              <div class="col pt-5 pt-md-0">
                <div
                  class="btn btn-outline-primary btn-block"
                  @click.prevent="onDatePickerInput({
                    start: $moment().startOf('day').toDate(),
                    end: $moment().endOf('day').toDate()
                  })"
                >
                  Today
                </div>

                <div
                  class="btn btn-outline-primary btn-block"
                  @click.prevent="onDatePickerInput({
                    start: $moment().subtract(1, 'days').startOf('day').toDate(),
                    end: $moment().subtract(1, 'days').endOf('day').toDate()
                  })"
                >
                  Yesterday
                </div>

                <div
                  class="btn btn-outline-primary btn-block"
                  @click.prevent="onDatePickerInput({
                    start: $moment().startOf('isoWeek').toDate(),
                    end: $moment().toDate()
                  })"
                >
                  This week
                </div>

                <div
                  class="btn btn-outline-primary btn-block"
                  @click.prevent="onDatePickerInput({
                    start: $moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(),
                    end: $moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()
                  })"
                >
                  Last week
                </div>

                <div
                  class="btn btn-outline-primary btn-block"
                  @click.prevent="onDatePickerInput({
                    start: $moment().startOf('month').toDate(),
                    end: $moment().toDate()
                  })"
                >
                  This month
                </div>

                <div
                  class="btn btn-outline-primary btn-block"
                  @click.prevent="onDatePickerInput({
                    start: $moment().subtract(1, 'month').startOf('month').toDate(),
                    end: $moment().subtract(1, 'month').endOf('month').toDate()
                  })"
                >
                  Last month
                </div>
              </div>
            </div>
          </template>
          </b-dropdown>
        </div>
      </div>

      <div class="form-group row border-bottom pb-3 mb-3">
        <div class="col-12 col-md-9 col-form-label my-md-auto">
          <h5 class="overflow-ellipsis">Log types</h5>
          <span>Select the desired log type(s).</span>
        </div>
        <div class="col col-form-label mt-3 my-md-auto">
          <div class="checkbox-list">
            <label
              v-for="log_type in ['parameter', 'boot', 'session', 'error', 'event']"
              :key="`checkbox.log_type.${log_type}`"
              class="checkbox checkbox-info"
            >
              <input
                type="checkbox"
                :value="log_type"
                v-model="form.log_types"
                :disabled="isLoadingForm"
              />
               <span :class="[{ 'border-danger': $v.form.log_types.$error }]" />
               {{ format.capitalizeFirstLetter($tc(`luxura_logs.${log_type}.title`, 2)) }}
            </label>
          </div>
        </div>
      </div>

      <div class="form-group row mb-3">
        <div class="col-auto ml-auto">
          <button
            type="submit"
            class="btn btn-info"
            :disabled="isLoadingForm"
          >
            Export logs
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/plugins/datepicker/index.scss';
</style>

<script>
import moment from 'moment-timezone';
import { required } from 'vuelidate/lib/validators';
import { validations } from '@vedicium/core-vue';
import format from '@/libs/format';

import Equipment from '@/libs/classes/equipment';

import DatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  components: {
    DatePicker,
  },
  data () {
    return {
      isLoadingForm: false,
      form: {
        log_types: [],
        range: {
          start: moment.utc().startOf('year').toDate(),
          end: new Date(),
        },
      },

      datePicker: {
        minDate: moment().set('year', 2016).startOf('year').toDate(),
        maxDate: new Date(),
        attributes: [{
          bar: {
            class: 'bg-primary',
          },
          highlight: {
            class: 'bg-primary',
            contentClass: 'bg-success',
          },
        }],
      },

      format,
    };
  },
  computed: {
    equipment () {
      return this.$store.get('sunbeds:view:equipment');
    },
  },
  validations: {
    form: {
      log_types: {
        required,
      },
      range: {
        start: {
          required,
        },
        end: {
          required,
        },
      },
    },
  },

  mounted () {
    this.resetForm();
  },

  methods: {
    resetForm () {
      this.$set(this, 'form', {
        log_types: [],
        range: {
          start: moment.utc().startOf('year').toDate(),
          end: new Date(),
        },
      });

      this.$v.$reset();
    },

    async validate () {
      await validations.validateArray([this.$v]);
    },
    async onSubmit () {
      this.$errors.clear();
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        // Initiate export request
        await this.$ws.post(`${Equipment.uri}/logs/export`, {
          query: {
            'filter[equipment_guid]': `${this.equipment._meta.guid},${this.equipment._meta.guid}`,
            'filter[type]': this.form.log_types,
            'filter[log_date|gte]': this.form.range.start.getTime(),
            'filter[log_date|lte]': this.form.range.end.getTime(),
          },
        });

        // Inform user what's going to happen
        this.$metronic.eventhub.emit('modals:layout:information:open', {
          title: 'Export initiated',

          message: 'We are preparing your download. Feel free to continue using MyLuxura in the mean time. <br />When the requested file is ready you will receive an email with further instructions.',
        });

        // Reset form
        this.resetForm();
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },

    async onDatePickerInput (range = null) {
      if (!range || !range.start || !range.end) {
        return;
      }

      if (range.start instanceof Date === false || range.end instanceof Date === false) {
        return;
      }

      // Hide dropdown and set range
      this.$refs['range-dropdown'].hide();
      this.$set(this.form, 'range', {
        start: moment(range.start).startOf('day').toDate(),
        end: moment(range.end).endOf('day').toDate(),
      });
    },
  },
};
</script>
